import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const HouseIconLarge = ({ className, stroke, fill }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.75 14.25V21.75H9.75V16C9.75 15.1716 10.4216 14.25 11.25 14.25H12.75C13.5784 14.25 14.25 15.1716 14.25 16V21.75H20.25V14.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 12.75L11 2.75C11.2813 2.46849 11.602 2.25 12 2.25C12.398 2.25 12.7187 2.46849 13 2.75L23.25 12.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default asIcon(HouseIconLarge);
