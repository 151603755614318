import React from 'react';

import Footer from '@layouts/mobile/Footer/Footer';
import FooterNavigation from '@layouts/mobile/FooterNavigation/FooterNavigation';
import MobileTopHeader from '@layouts/mobile/MobileTopHeader/MobileTopHeader';
import Main from '@layouts/responsive/Main/Main';

import style from './MobileErrorLayout.module.scss';

type MobileErrorLayoutProp = {
  children: React.ReactNode;
};

const MobileErrorLayout = ({ children }: MobileErrorLayoutProp) => {
  return (
    <div className={style.height}>
      <MobileTopHeader showLogo showKpMenu isFixed />
      <div className={style.content}>
        <div className={style.container}>
          <Main>{children}</Main>
        </div>
      </div>
      <Footer />
      <FooterNavigation />
    </div>
  );
};

export default MobileErrorLayout;
