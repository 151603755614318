import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const NotificationIconLarge = ({ className, stroke, fill }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.995 22C10.2559 22.8887 11.0713 23.4992 11.9975 23.4992C12.9237 23.4992 13.7391 22.8887 14 22"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 2.5V1.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C16.1421 3 19.5 6.10786 19.5 10.25C19.5 17.296 21 18.5 21 18.5H3C3 18.5 4.5 16.584 4.5 10.25C4.5 6.10786 7.85786 3 12 3Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default asIcon(NotificationIconLarge);
