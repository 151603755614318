import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { getYear } from 'src/lib/getYear';

import {
  CourierTermsAndConditionsRoute,
  FairUsePolicyRoute,
  HelpAndContactRoute,
  KPAppleStoreRoute,
  KPBlogRoute,
  KPBlogSafeTradeRoute,
  KPBookBearRoute,
  KPPlayStoreRoute,
  PrivacyPolicyRoute,
  SellerRightsRoute,
  TermsAndConditionsRoute,
} from '@server/routes';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import useScreen from '@hooks/useScreen';

import { openFeedbackWithOption } from '@store/feedback/slice';
import { selectFeatureFlags } from '@store/generalStats/selector';
import { selectIsMobile } from '@store/meta/selector';

import AppleStoreLogo from '@components/common/responsive/Logo/AppleStoreLogo';
import GooglePlayLogo from '@components/common/responsive/Logo/GooglePlayLogo';
import ButtonAsLink from '@components/controls/Button/ButtonAsLink';
import Link from '@components/controls/Link/Link';

import style from './Footer.module.scss';

const setClass = classNames.bind(style);

type FooterProps = {
  simple?: boolean;
};

const Footer = ({ simple = false }: FooterProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(selectIsMobile);
  const { openScreen } = useScreen();
  const { isCourierOrderEnabled = false } = useAppSelector(selectFeatureFlags);

  return (
    <footer className={setClass({ footer: true, simple })}>
      {!simple && (
        <>
          <div className={style.row}>
            <Link href={HelpAndContactRoute.generateUrl()}>
              Pomoć i kontakt
            </Link>
            |
            <ButtonAsLink
              className={style.feedbackButton}
              small
              onClick={() => {
                if (isMobile) {
                  openScreen('feedbackForm');
                }
                dispatch(openFeedbackWithOption('media'));
              }}
            >
              Kontakt za medije
            </ButtonAsLink>
            |<Link href={KPBlogRoute.generateUrl()}>KP Blog</Link>
          </div>

          <div className={style.row}>
            <Link href={TermsAndConditionsRoute.generateUrl()}>
              Pravila i uslovi
            </Link>
            |
            <Link href={PrivacyPolicyRoute.generateUrl()}>
              Polisa o poštovanju privatnosti
            </Link>
          </div>

          <div className={style.row}>
            <Link href={FairUsePolicyRoute.generateUrl()}>
              Polisa o fer korišćenju
            </Link>
            |
            <Link href={SellerRightsRoute.generateUrl()}>
              Prava i obaveze prodavaca
            </Link>
          </div>

          {isCourierOrderEnabled && (
            <div className={style.row}>
              <Link href={CourierTermsAndConditionsRoute.generateUrl()}>
                Pravila i uslovi zakazivanja kurira
              </Link>
            </div>
          )}

          <div className={style.row}>
            <Link href={KPBlogSafeTradeRoute.generateUrl()}>
              Saveti za bezbednost
            </Link>
          </div>

          <div className={style.row}>
            <Link
              ariaLabel="Knjiga o nama - Medvedi na putu"
              href={KPBookBearRoute.generateUrl()}
            >
              Knjiga o nama -&nbsp;<b>Medvedi na putu</b>
            </Link>
          </div>
          <div className={style.appStoreInfo}>
            <Link
              ariaLabel="KupujemProdajem PlayStore link"
              href={KPPlayStoreRoute.generateUrl()}
              rel="noopener"
              target="_blank"
            >
              <GooglePlayLogo />
            </Link>
            <Link
              ariaLabel="KupujemProdajem AppleStore link"
              href={KPAppleStoreRoute.generateUrl()}
              rel="noopener"
              target="_blank"
            >
              <AppleStoreLogo />
            </Link>
          </div>
        </>
      )}
      <div className={style.footerBottom}>
        <p>
          {`KupujemProdajem © ${getYear()} `}by Quable B.V. The Netherlands.
        </p>
        <p>All rights reserved.</p>
      </div>
    </footer>
  );
};

export default memo(Footer);
