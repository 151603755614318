import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const MyAdsIconLarge = ({ className, stroke, fill }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.5 9.5V6.5C20.5 5.67157 19.8284 5.5 19 5.5H8.5V3.5C8.5 2.5 7.5 2.5 6.5 2.5H2C1 2.5 0.5 3 0.5 3.75V20C0.5 21 1 21.5 2 21.5M2 21.5C3 21.5 4.5 21.5 4.5 20.5L6.5 10.5C6.5 10 7 9.5 8 9.5H21.5C22 9.5 22.716 9.63055 23 10C23.3125 10.4065 23.5 11 23.5 11.5L21.5 20.5C21.325 21.1571 20.68 21.5002 20 21.5H2Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default asIcon(MyAdsIconLarge);
