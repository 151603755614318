import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const AddIconLarge = ({ className, stroke, fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12 7.5V16.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 11.5H16.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 3.5C0.5 1.84315 1.84315 0.5 3.5 0.5H20.5C22.1569 0.5 23.5 1.84315 23.5 3.5V20.5C23.5 22.1569 22.1569 23.5 20.5 23.5H3.5C1.84315 23.5 0.5 22.1569 0.5 20.5V3.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default asIcon(AddIconLarge);
