import { useRouter } from 'next/router';

import { toggleScreen } from '@store/modal/slice';

import { useAppDispatch } from './redux/useAppDispatch';

const useScreen = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const openScreen = (name: string) => {
    dispatch(toggleScreen(name));
  };

  const openScreenWithUrl = (name: string) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, screen: name },
      },
      undefined,
      { shallow: true }
    );
  };

  return {
    openScreen,
    openScreenWithUrl,
  };
};

export default useScreen;
