import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';

import {
  HomeRoute,
  MyKPAdsRoute,
  MyKPMessagesRoute,
  MyKPNotificationsRoute,
  NewAdRoute,
} from '@server/routes';

import useAdSaveEvents from '@hooks/ga4/useAdSaveEvents';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import useDetectKeyboardOpen from '@hooks/useDetectKeyboardOpen';

import { selectUnreadNotification } from '@store/meStats/selector';
import { selectDisplayMenu } from '@store/myKpHeader/selector';

import Badge from '@components/common/responsive/Badge/Badge';
import Link from '@components/controls/Link/Link';
import MyAdsIconLarge from '@components/icons/business/MyAdsIconLarge';
import HouseIconLarge from '@components/icons/common/HouseIconLarge';
import MessageIconLarge from '@components/icons/common/MessageIconLarge';
import NotificationIconLarge from '@components/icons/common/NotificationIconLarge';
import AddIconLarge from '@components/icons/functions/AddIconLarge';

import style from './FooterNavigation.module.scss';

const setClass = classNames.bind(style);

const FooterNavigation = () => {
  const { messages, notifications } = useAppSelector(selectUnreadNotification);
  const isSidebarOpen = useAppSelector(selectDisplayMenu);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const isNavigationHidden = isSidebarOpen || isKeyboardOpen;
  const { route: path } = useRouter();

  const ROUTES = {
    home: HomeRoute.generateUrl(),
    message: MyKPMessagesRoute.generateUrl(),
    newAd: NewAdRoute.generateUrl(),
    notification: MyKPNotificationsRoute.generateUrl(),
    ads: MyKPAdsRoute.generateUrl(),
  };

  const getActiveClassName = (route: string) => {
    return setClass({
      navItem: true,
      isActive: route === path,
    });
  };

  const { clickNewAdEvent } = useAdSaveEvents();

  return (
    <nav
      className={setClass({
        navHolder: true,
      })}
      data-state={isNavigationHidden ? 'hide' : 'show'}
    >
      <Link
        ariaLabel="Početna"
        inherit
        href={ROUTES.home}
        className={getActiveClassName(ROUTES.home)}
      >
        <HouseIconLarge />
        Početna
      </Link>
      <Link
        ariaLabel={`${messages} Poruke`}
        inherit
        href={ROUTES.message}
        className={getActiveClassName(ROUTES.message)}
      >
        <Badge ariaHidden content={messages}>
          <MessageIconLarge />
        </Badge>
        Poruke
      </Link>
      <Link
        ariaLabel="Novi oglas"
        inherit
        href={ROUTES.newAd}
        className={getActiveClassName(ROUTES.newAd)}
        doLog={clickNewAdEvent}
      >
        <AddIconLarge />
        Novi oglas
      </Link>
      <Link
        ariaLabel={`${notifications} Obaveštenja`}
        inherit
        href={ROUTES.notification}
        className={getActiveClassName(ROUTES.notification)}
      >
        <Badge ariaHidden content={notifications}>
          <NotificationIconLarge />
        </Badge>
        Obaveštenja
      </Link>
      <Link
        ariaLabel="Moji oglasi"
        inherit
        href={ROUTES.ads}
        className={getActiveClassName(ROUTES.ads)}
      >
        <MyAdsIconLarge />
        Moji oglasi
      </Link>
    </nav>
  );
};

export default FooterNavigation;
