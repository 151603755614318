import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const MessageIconLarge = ({ className, stroke, fill }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 18.5C10.1716 18.5 9.5 17.8284 9.5 17V10C9.5 9.17157 10.1716 8.5 11 8.5H22C22.8284 8.5 23.5 9.17157 23.5 10V17C23.5 17.8284 22.8284 18.5 22 18.5H20.5V23L15.5 18.5H11Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 12.5L3.5 15.5V10.5H2C1.17157 10.5 0.5 9.82843 0.5 9V2C0.5 1.17157 1.17157 0.5 2 0.5H12C12.8284 0.5 13.5 1.17157 13.5 2V5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default asIcon(MessageIconLarge);
